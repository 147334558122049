import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ProductDetails from './ProductDetails';
import {Grid} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@mui/material/Box';
import {SERVER_URL} from '../../../constants';

export default function ProductDetailsDialog(props: $TSFixMe) {
  const [mode, setMode] = React.useState<string>('view');
  const [product, setProduct] = React.useState(props.product);
  const [loading, setLoading] = React.useState(true);

  const handleClose = () => {
    props.handleClose();
  };

  const getData = () => {
    const url = `${SERVER_URL}v1/products/${props.product.uid}`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        setProduct(res);
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'md'}
        fullWidth
        disableEnforceFocus>
        <Grid
          direction={'row'}
          container
          justifyContent={'space-between'}
          spacing={2}>
          <Grid item>
            <DialogTitle id="form-dialog-title">{product.name}</DialogTitle>
          </Grid>
          <Grid item>
            <Box m={1}>
              {mode === 'view' && (
                <IconButton>
                  <EditIcon onClick={() => setMode('edit')} />
                </IconButton>
              )}
              {['edit', 'save'].includes(mode) && (
                <Button
                  disabled={mode === 'save'}
                  onClick={() => setMode('save')}
                  variant={'contained'}
                  color="secondary">
                  Save
                </Button>
              )}
              {mode === 'edit' && (
                <Button onClick={() => setMode('view')} color="secondary">
                  Cancel
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>

        <DialogContent>
          {!loading && (
            <ProductDetails
              {...props}
              product={product}
              mode={mode}
              setMode={setMode}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
