import React, {useEffect} from 'react';
import {
  Autocomplete,
  DialogTitle,
  FormControl,
  InputLabel,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {DialogActions} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {SERVER_URL} from '../../../constants';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

type PromptOption = {
  id?: number;
  inputValue?: string;
  prompt: string;
  promptType?: string | null;
};

const filter = createFilterOptions<PromptOption>();

export default function SelectPromptWidget(props: $TSFixMe) {
  const [value, setValue] = React.useState<PromptOption | null>(null);
  const [open, toggleOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [promptOptions, setPromptOptions] = React.useState<PromptOption[]>([]);

  const [newPrompt, setNewPrompt] = React.useState('');
  const [newPromptType, setNewPromptType] = React.useState<string | null>(null);

  useEffect(() => {
    if (value) {
      props.setPrompts((prevState: $TSFixMe) => [...prevState, value]);
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClose = () => {
    setNewPrompt('');
    setNewPromptType('');
    toggleOpen(false);
  };

  const getPromptsList = () => {
    const jwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/prompts`, {
      method: 'GET',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
    })
      .then((res) => {
        if (res.status === 409) {
          return res;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        console.log(res);
        setPromptOptions(res._embedded?.models || []);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  useEffect(() => {
    getPromptsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const jwt = localStorage.getItem('Authorization');
    const body = JSON.stringify({
      prompt: newPrompt,
      type: newPromptType,
    });

    return fetch(`${SERVER_URL}v1/products/${props.product.uid}/prompts`, {
      method: 'POST',
      // @ts-ignore
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
      body: body,
    })
      .then((res) => res.json())
      .then((res) => {
        setValue({
          id: res.id,
          prompt: newPrompt,
          promptType: newPromptType,
        });
        handleClose();
      });
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <Autocomplete
        style={{width: '100%'}}
        value={value}
        onChange={(event, newValue) => {
          console.log('On change!!!');

          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setNewPrompt(newValue);
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setNewPrompt(newValue.inputValue);
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              prompt: params.inputValue,
              promptType: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="select-prompt-autoselect"
        options={promptOptions}
        getOptionLabel={(option) => {
          // for example value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if ('inputValue' in option && option.inputValue) {
            return option.inputValue;
          }
          return option.prompt;
        }}
        selectOnFocus
        handleHomeEndKeys
        clearOnBlur
        renderOption={(props, option) => {
          // @ts-ignore
          const {key, ...optionProps} = props;
          return (
            <li key={key} {...optionProps}>
              {option.prompt}
            </li>
          );
        }}
        sx={{width: 300}}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Prompt"
            color={'secondary'}
            fullWidth
          />
        )}
      />
      <Dialog
        maxWidth={'sm'}
        fullWidth
        open={open}
        onClose={handleClose}
        disableEnforceFocus>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create Prompt</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="new-prompt-name"
              name={'new-prompt-name'}
              value={newPrompt}
              onChange={(event) => setNewPrompt(event.target.value)}
              label="Prompt"
              type="text"
              variant="standard"
              color={'secondary'}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel id="new-prompt-type">Type</InputLabel>
              <Select
                labelId="new-prompt-type"
                id="new-prompt-type-id"
                value={newPromptType}
                label="Type"
                onChange={(event) => setNewPromptType(event.target.value)}>
                <MenuItem value={''}>Select a type</MenuItem>
                <MenuItem value={'SHORT_FORM_TEXT'}>Short Form Text</MenuItem>
                <MenuItem value={'LONG_FORM_TEXT'}>Long Form Text</MenuItem>
                <MenuItem value={'BOOLEAN'}>Yes or No</MenuItem>
                <MenuItem value={'MULTIPLE_CHOICE'}>Multiple Choice</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button color={'secondary'} onClick={handleClose}>
              Cancel
            </Button>
            <Button color={'secondary'} type="submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
