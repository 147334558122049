import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

export default function ServiceOrderFormActions(props: $TSFixMe) {
  const handleEdit = () => {
    props.setMode('edit');
  };

  const handleSave = () => {
    props.getData();
    props.setMode('view');
  };

  return (
    <DialogActions>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {props.mode === 'view' ? (
          <>
            <Tooltip title={'Edit'}>
              <IconButton
                color="inherit"
                aria-label="edit project"
                component="span"
                onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title={'Save'}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave}>
                Save
              </Button>
            </Tooltip>
            <Tooltip title={'Cancel'}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.setMode('view')}>
                Cancel
              </Button>
            </Tooltip>
          </>
        )}
      </div>
    </DialogActions>
  );
}
