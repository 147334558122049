import React, {useEffect} from 'react';
import {Autocomplete} from '@mui/material';
import TextField from '@material-ui/core/TextField';
import {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {SERVER_URL} from '../constants';

type PartnerOption = {
  id?: number;
  inputValue?: string;
  name: string;
  price?: number | null;
};

const filter = createFilterOptions<PartnerOption>();

export default function SelectPartnerWidget(props: $TSFixMe) {
  const [value, setValue] = React.useState<PartnerOption | null>(null);
  const [loading, setLoading] = React.useState(true);

  const [partnerOptions, setPartnerOptions] = React.useState<PartnerOption[]>(
    [],
  );

  useEffect(() => {
    if (value) {
      props.setPartner(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getPartnerList = () => {
    const jwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/users?userType=PARTNER`, {
      method: 'GET',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
    })
      .then((res) => {
        if (res.status === 409) {
          return res;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        console.log(res);
        setPartnerOptions(res._embedded?.models || []);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  useEffect(() => {
    getPartnerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }

  return (
    <>
      <Autocomplete
        style={{width: '100%'}}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="select-partner-autoselect"
        options={partnerOptions}
        getOptionLabel={(option) => {
          // for example value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if ('inputValue' in option && option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        handleHomeEndKeys
        clearOnBlur
        renderOption={(props, option) => {
          // @ts-ignore
          const {key, ...optionProps} = props;
          return (
            <li key={key} {...optionProps}>
              {option.name}
            </li>
          );
        }}
        sx={{width: 300}}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Partner"
            color={'secondary'}
            fullWidth
          />
        )}
      />
    </>
  );
}
