import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {TableFooter} from '@mui/material';
import SelectProductWidget from './SelectProductWidget';

export default function ProductList(props: $TSFixMe) {
  return (
    <div style={{marginTop: 30, marginLeft: 15, width: 650}}>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 350}} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{width: '80%'}}>Product Name</TableCell>
              <TableCell style={{width: '20%'}} align="right">
                Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.products.map((row: $TSFixMe) => (
              <TableRow
                key={row.name}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell style={{width: '80%'}} component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell style={{width: '20%'}} align="right">
                  {row.price}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell style={{width: '80%'}}>
                <SelectProductWidget setProducts={props.setProducts} />
              </TableCell>
              <TableCell style={{width: '20%'}}></TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell style={{fontWeight: 600, width: '80%'}}>
                Total
              </TableCell>
              <TableCell style={{fontWeight: 600, width: '20%'}}></TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
