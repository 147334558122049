import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import {Typography} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import {useHistory} from 'react-router-dom';
import ServiceOrderFormActions from './ServiceOrderFormActions';
import {SERVER_URL} from '../../../constants';
import ServiceOrderNotesPanel from './ServiceOrderNotesPanel';
import ServiceOrderDetails from './ServiceOrderDetails';

const styles = (theme: $TSFixMe) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  root: {
    width: '100%',
    alignItems: 'center',
  },

  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  actionsContainer: {
    marginBottom: theme.spacing(2),
  },

  resetContainer: {
    padding: theme.spacing(3),
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

function TransitionUp(props: $TSFixMe) {
  return <Slide {...props} direction="up" />;
}

function ServiceOrderForm(props: $TSFixMe) {
  const history = useHistory();
  const {classes} = props;
  const [mode, setMode] = useState('view');
  const [serviceOrder, setServiceOrder] = useState<$TSFixMe>(null);

  const getData = () => {
    const url = `${SERVER_URL}v1/service-orders/${(props as $TSFixMe).match.params.id}`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        setServiceOrder(res);
      });
  };

  const handleClose = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!serviceOrder) {
    return null;
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="Job"
        TransitionComponent={TransitionUp}
        fullScreen>
        <AppBar
          position="fixed"
          elevation={0}
          style={{
            overflowX: 'auto',
            backgroundColor: '#D6EFFF',
            borderBottom: '1px solid #d9d9d9',
          }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title} />
            <ServiceOrderFormActions mode={mode} setMode={setMode} />
          </Toolbar>
        </AppBar>
        <div
          style={{
            height: '100vh',
            alignItems: 'center',
            padding: window.innerWidth > 600 ? '64px 30px 0' : '64px 8px 0',
          }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              {mode === 'view' ? (
                <ServiceOrderDetails serviceOrder={serviceOrder} />
              ) : (
                <div
                  style={{
                    height: 600,
                    width: 600,
                    backgroundColor: 'green',
                  }}></div>
              )}
            </Grid>
            <ServiceOrderNotesPanel {...props} />
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(ServiceOrderForm);
