import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import {DialogTitle} from '@mui/material';
import ServiceOrderAddress from './ServiceOrderAddress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../common/TabPanel';
import ServiceOrderDetailsTabPanel from './ServiceOrderDetailsTabPanel';

const styles = (theme: $TSFixMe) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  root: {
    width: '100%',
    alignItems: 'center',
    marginTop: 20,
    //marginLeft: 20,
    marginRight: 30,
    marginBottom: 30,
    border: '1px solid #ddd',
    borderRadius: '1rem',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  addressContainer: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tabs: {
    backgroundColor: '#f7f7f7',
    borderTop: '1px solid #d9d9d9',
    borderBottom: '1px solid #d9d9d9',

    '& .MuiTab-root': {
      minWidth: window.innerWidth > 600 ? 110 : 'auto',
    },
  },
});

function ServiceOrderDetails(props: $TSFixMe) {
  const {classes} = props;
  const [tabValue, setTabValue] = useState('details');

  const handleTabChange = (event: $TSFixMe, newValue: $TSFixMe) => {
    setTabValue(newValue);
  };

  /*
  attachmentReferences: []
  completionDate: null
  contactEmail: "phillipstack@gmail.com"
  contactFirstName: "Jim"
  contactLastName: "Renter"
  contactPhone: "5195469170"
  createdDate: "2024-12-20T19:27:00.929+00:00"
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
  id: 1
  name: "SO-000001"
  productPackage: null
  products: []
  reference: "ThisIsAReference"
  requestForProposal: null
  scheduledDate: null
  state: "PENDING"
  technicians: []
  uid: "40f649fb-7166-4f1a-bf41-b20ba32dc809"
  unitAccessNotes: "These are some unit access notes."
   */

  return (
    <Box className={classes.root}>
      <DialogTitle className={classes.title}>
        {props.serviceOrder.name}
      </DialogTitle>
      <Box className={classes.addressContainer}>
        <ServiceOrderAddress {...props} />
      </Box>

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        textColor="secondary"
        variant="scrollable"
        scrollButtons="on"
        className={classes.tabs}>
        <Tab label="DETAILS" value={'details'} />
      </Tabs>
      <TabPanel value={'details'} selectedValue={tabValue} index={0}>
        <ServiceOrderDetailsTabPanel {...props} />
      </TabPanel>
    </Box>
  );
}

export default withStyles(styles)(ServiceOrderDetails);
