import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import {Typography} from '@material-ui/core';

const styles = (theme: $TSFixMe) => ({
  address: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
});

function ServiceOrderAddress(props: $TSFixMe) {
  const {classes} = props;

  return (
    <Box className={classes.address}>
      <Typography variant="body2" color="textSecondary" component="span">
        {props.serviceOrder.address.street}{' '}
        {props.serviceOrder.address.street2 &&
          ` ${props.serviceOrder.address.street2}`}
      </Typography>
      <br />
      <Typography variant="body2" color="textSecondary" component="span">
        {props.serviceOrder.address.city}, {props.serviceOrder.address.province}
      </Typography>
      <br />
      <Typography variant="body2" color="textSecondary" component="span">
        {props.serviceOrder.address.postal}
      </Typography>
    </Box>
  );
}

export default withStyles(styles)(ServiceOrderAddress);
