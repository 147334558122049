import React from 'react';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import {GMAPS_API_KEY, SERVER_URL} from '../constants';

export default function AddressLookupWidget(props: $TSFixMe) {
  const searchAddress = (locationId: string, locationData: $TSFixMe) => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/addresses?placeId=${locationId}`, {
      method: 'GET',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res._embedded) {
          createAddress(locationId, locationData);
        } else {
          props.setAddressId(res._embedded.models[0].id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createAddress = (locationId: string, res: $TSFixMe) => {
    const lat = res[0].geometry.location.lat();
    const lng = res[0].geometry.location.lng();
    const postal = (
      res[0].address_components.filter(
        (c: any) => (c as $TSFixMe).types[0] === 'postal_code',
      )[0] as $TSFixMe
    ).short_name;
    const street = res[0].formatted_address.split(',')[0];
    const city = (
      res[0].address_components.filter(
        (c: any) => (c as $TSFixMe).types[0] === 'locality',
      )[0] as $TSFixMe
    ).short_name;
    const province = (
      res[0].address_components.filter(
        (c: any) => (c as $TSFixMe).types[0] === 'administrative_area_level_1',
      )[0] as $TSFixMe
    ).long_name;
    const country = (
      res[0].address_components.filter(
        (c: any) => (c as $TSFixMe).types[0] === 'country',
      )[0] as $TSFixMe
    ).short_name;

    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/addresses`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({
        placeId: locationId,
        street: street,
        city: city,
        province: province,
        postal: postal,
        country: country,
        lat: lat,
        lng: lng,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.id) {
          props.setAddressId(res.id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onAddressSelection = (location: $TSFixMe) => {
    const placeId = location.value.place_id;
    geocodeByPlaceId(placeId).then((res) => {
      searchAddress(placeId, res);
    });
  };

  return (
    <div style={{height: 300}}>
      <GooglePlacesAutocomplete
        apiKey={GMAPS_API_KEY}
        selectProps={{
          onChange: (location) => onAddressSelection(location),
          placeholder: 'Address Lookup',
        }}
        autocompletionRequest={{
          componentRestrictions: {
            country: ['ca'],
          },
          types: ['address'],
        }}
      />
    </div>
  );
}
