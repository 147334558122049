import React, {useState, useEffect} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import HomeIcon from '@material-ui/icons/Home';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import UnsubscribeIcon from '@material-ui/icons/Unsubscribe';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BusinessIcon from '@material-ui/icons/Business';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import NotificationsIcon from '@material-ui/icons/NotificationsActive';
import CasinoIcon from '@material-ui/icons/Casino';
import {QuestionAnswer} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {SERVER_URL} from '../../constants';
import Badge from '@material-ui/core/Badge';
import {questionsCountState} from '../../recoil/questions/questionsAtom';
import {useRecoilState} from 'recoil';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CategoryIcon from '@mui/icons-material/Category';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import InventoryIcon from '@mui/icons-material/Inventory';

const DrawerMenuOptions = (props: $TSFixMe) => {
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [questionsReviewCount, setQuestionsReviewCount] =
    useRecoilState(questionsCountState);

  const getSelectedIndex = () => {
    const pathParts = window.location.pathname.split('/');
    const path = pathParts[pathParts.length - 1];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    setSelectedIndex(path);
  };

  const handleListItemClick = (event: $TSFixMe, key: $TSFixMe) => {
    setSelectedIndex(key);
    history.push(`${key}`);
  };

  const handleClickLogout = () => {
    localStorage.removeItem('Authorization');
    window.location.href = '/login';
  };

  const getQuestionsToReviewCount = () => {
    const fbjwt = localStorage.getItem('Authorization');
    fetch(`${SERVER_URL}v1/questions/review-count`, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setQuestionsReviewCount(res);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  useEffect(() => {
    getSelectedIndex();
    getQuestionsToReviewCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <List>
        <ListItem
          button
          key="home"
          selected={selectedIndex === '/my/dashboard'}
          onClick={(event) => handleListItemClick(event, '/my/dashboard')}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="My Dashboard" />
        </ListItem>

        <ListItem
          button
          key="metrics"
          selected={selectedIndex === '/metrics'}
          onClick={(event) => handleListItemClick(event, '/metrics')}>
          <ListItemIcon>
            <TrendingUpIcon />
          </ListItemIcon>
          <ListItemText primary="Metrics" />
        </ListItem>

        <ListItem
          button
          key="myRfps"
          selected={selectedIndex === '/myRfps'}
          onClick={(event) => handleListItemClick(event, '/myRfps')}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Projects" />
        </ListItem>
        <ListItem
          button
          key="service-orders"
          selected={selectedIndex === '/service-orders'}
          onClick={(event) => handleListItemClick(event, '/service-orders')}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Service Orders" />
        </ListItem>
        <ListItem
          button
          key="users"
          selected={selectedIndex === '/users'}
          onClick={(event) => handleListItemClick(event, '/users')}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem
          button
          key="project_templates"
          selected={selectedIndex === '/transactions'}
          onClick={(event) => handleListItemClick(event, '/transactions')}>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Transactions" />
        </ListItem>
        <ListItem
          button
          key="transactions"
          selected={selectedIndex === '/project-templates'}
          onClick={(event) => handleListItemClick(event, '/project-templates')}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Project Templates" />
        </ListItem>
        <ListItem
          button
          key="promocodes"
          selected={selectedIndex === '/promocodes'}
          onClick={(event) => handleListItemClick(event, '/promocodes')}>
          <ListItemIcon>
            <CasinoIcon />
          </ListItemIcon>
          <ListItemText primary="Promo Codes" />
        </ListItem>
        <ListItem
          button
          key="accounting"
          selected={selectedIndex === '/accounting'}
          onClick={(event) => handleListItemClick(event, '/accounting')}>
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Accounting" />
        </ListItem>
        <ListItem
          button
          key="unsubscribes"
          selected={selectedIndex === '/unsubscribes'}
          onClick={(event) => handleListItemClick(event, '/unsubscribes')}>
          <ListItemIcon>
            <UnsubscribeIcon />
          </ListItemIcon>
          <ListItemText primary="Unsubscribes" />
        </ListItem>
        <ListItem
          button
          key="notifications"
          selected={selectedIndex === '/notifications'}
          onClick={(event) => handleListItemClick(event, '/notifications')}>
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary="Notifications" />
        </ListItem>
        <ListItem
          button
          key="questions"
          selected={selectedIndex === '/questions'}
          onClick={(event) => handleListItemClick(event, '/questions')}>
          <ListItemIcon>
            <Badge
              badgeContent={questionsReviewCount}
              overlap="rectangular"
              color={'error'}>
              <QuestionAnswer />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Questions" />
        </ListItem>
        <ListItem
          button
          key="user_feedback"
          selected={selectedIndex === '/feedback'}
          onClick={(event) => handleListItemClick(event, '/feedback')}>
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary="User feedback" />
        </ListItem>
        <ListItem
          button
          key="project_categories"
          selected={selectedIndex === '/categories'}
          onClick={(event) => handleListItemClick(event, '/categories')}>
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItem>
        <ListItem
          button
          key="badges"
          selected={selectedIndex === '/badges'}
          onClick={(event) => handleListItemClick(event, '/badges')}>
          <ListItemIcon>
            <MilitaryTechIcon />
          </ListItemIcon>
          <ListItemText primary="Badges" />
        </ListItem>
        <ListItem
          button
          key="product-packages"
          selected={selectedIndex === '/product-packages'}
          onClick={(event) => handleListItemClick(event, '/product-packages')}>
          <ListItemIcon>
            <InventoryIcon />
          </ListItemIcon>
          <ListItemText primary="Product Packages" />
        </ListItem>
        <ListItem
          button
          key="products"
          selected={selectedIndex === '/products'}
          onClick={(event) => handleListItemClick(event, '/products')}>
          <ListItemIcon>
            <InventoryIcon />
          </ListItemIcon>
          <ListItemText primary="Products" />
        </ListItem>
        <ListItem button key="logout" onClick={handleClickLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );
};

export default DrawerMenuOptions;
