import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import {Typography} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import ProductPromptsList from './ProductPromptsList';

export default function ProductDetails(props: $TSFixMe) {
  const [prompts, setPrompts] = useState(props.product?.prompts || []);

  useEffect(() => {
    if (props.mode === 'save') {
      setTimeout(function () {
        props.setMode('view');
      }, 3500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode]);

  if (!props.product) {
    return null;
  }

  return props.mode === 'view' ? (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography>Price: ${props.product.price}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box mt={2}>
          <Typography>Prompts</Typography>
          <ProductPromptsList
            product={props.product}
            key={'product-prompts-list'}
            prompts={prompts}
            setPrompts={setPrompts}
            mode={'view'}
          />
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <TextField
          disabled={props.mode === 'save'}
          fullWidth
          label="Product Name"
          variant="outlined"
          value={props.product.name}
          InputLabelProps={{shrink: true}}
          style={{marginBottom: 12}}
        />
        <TextField
          disabled={props.mode === 'save'}
          fullWidth
          label="Price"
          variant="outlined"
          value={props.product.price}
          InputLabelProps={{shrink: true}}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box mt={2}>
          <Typography>Prompts</Typography>
          <ProductPromptsList
            product={props.product}
            key={'product-prompts-list'}
            prompts={prompts}
            setPrompts={setPrompts}
            mode={'edit'}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
