import React, {useEffect} from 'react';
import {Autocomplete, DialogTitle} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {DialogActions} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {SERVER_URL} from '../../../constants';

type ProductOption = {
  id?: number;
  inputValue?: string;
  name: string;
  price?: number | null;
};

const filter = createFilterOptions<ProductOption>();

export default function SelectProductWidget(props: $TSFixMe) {
  const [value, setValue] = React.useState<ProductOption | null>(null);
  const [open, toggleOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [productOptions, setProductOptions] = React.useState<ProductOption[]>(
    [],
  );

  useEffect(() => {
    if (value) {
      props.setProducts((prevState: $TSFixMe) => [...prevState, value]);
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleClose = () => {
    setNewProductTitle('');
    setNewProductPrice(0);
    toggleOpen(false);
  };

  const [newProductTitle, setNewProductTitle] = React.useState('');
  const [newProductPrice, setNewProductPrice] = React.useState<number | null>(
    null,
  );

  const getProductList = () => {
    const jwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/products`, {
      method: 'GET',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
    })
      .then((res) => {
        if (res.status === 409) {
          return res;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        console.log(res);
        setProductOptions(res._embedded?.models || []);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  useEffect(() => {
    getProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createProduct = async () => {
    try {
      const jwt = localStorage.getItem('Authorization');
      const body = JSON.stringify({
        name: newProductTitle,
        price: newProductPrice,
      });

      const res = await fetch(`${SERVER_URL}v1/products`, {
        method: 'POST',
        // @ts-ignore
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwt,
        },
        body: body,
      });

      if (!res.ok) {
        // Check for any unsuccessful status code (4xx or 5xx)
        if (res.status === 403) {
          window.location.href = '/login';
        }
        // You might want to throw an error here to be caught by the catch block
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.json();
      return data; // Always return the parsed JSON response
    } catch (err) {
      console.error(err);
      // Handle other errors here, e.g., show an error message to the user
      return null; // Or throw the error to be handled in handleSubmit
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const product = await createProduct();
    setValue({
      id: product.id,
      name: newProductTitle,
      price: newProductPrice,
    });
    handleClose();
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <Autocomplete
        style={{width: '100%'}}
        value={value}
        onChange={(event, newValue) => {
          console.log('On change!!!');

          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setNewProductTitle(newValue);
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setNewProductTitle(newValue.inputValue);
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="select-product-autoselect"
        options={productOptions}
        getOptionLabel={(option) => {
          // for example value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if ('inputValue' in option && option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        handleHomeEndKeys
        clearOnBlur
        renderOption={(props, option) => {
          // @ts-ignore
          const {key, ...optionProps} = props;
          return (
            <li key={key} {...optionProps}>
              {option.name}
            </li>
          );
        }}
        sx={{width: 300}}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Product"
            color={'secondary'}
            fullWidth
          />
        )}
      />
      <Dialog
        maxWidth={'sm'}
        fullWidth
        open={open}
        onClose={handleClose}
        disableEnforceFocus>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Create Product</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="new-product-name"
              name={'new-product-name'}
              value={newProductTitle}
              onChange={(event) => setNewProductTitle(event.target.value)}
              label="Product name"
              type="text"
              variant="standard"
              color={'secondary'}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="new-product-price"
              name={'new-product-price'}
              value={newProductPrice}
              onChange={(event) =>
                setNewProductPrice(Number(event.target.value))
              }
              label="Price"
              type="number"
              variant="standard"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button color={'secondary'} onClick={handleClose}>
              Cancel
            </Button>
            <Button color={'secondary'} type="submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
