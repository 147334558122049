import React from 'react';
import Box from '@mui/material/Box';

type TabPanelProps = {
  children?: React.ReactNode;
  index: $TSFixMe;
  value: $TSFixMe;

  selectedValue: string;
};

export default function TabPanel(props: TabPanelProps) {
  const {children, value, selectedValue, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== selectedValue}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === selectedValue && (
        <Box p={3} key={value}>
          {children}
        </Box>
      )}
    </div>
  );
}
