import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SelectPromptWidget from './SelectPromptWidget';

export default function ProductPromptsList(props: $TSFixMe) {
  return (
    <div style={{marginTop: 30, marginLeft: 15, width: 650}}>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 350}} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{width: '80%'}}>Prompt</TableCell>
              <TableCell style={{width: '20%'}}>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.prompts.map((row: $TSFixMe) => (
              <TableRow
                key={row.uid}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell style={{width: '80%'}} component="th" scope="row">
                  {row.prompt}
                </TableCell>
                <TableCell style={{width: '20%'}} align="right">
                  {row.promptType}
                </TableCell>
              </TableRow>
            ))}
            {props.mode === 'edit' && (
              <TableRow>
                <TableCell style={{width: '80%'}}>
                  <SelectPromptWidget
                    product={props.product}
                    setPrompts={props.setPrompts}
                  />
                </TableCell>
                <TableCell style={{width: '20%'}}></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
