import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {SERVER_URL} from '../../../constants';
import {useHistory} from 'react-router-dom';
import {Snackbar} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import AddressLookupWidget from '../../../common/AddressLookupWidget';
import SelectProductWidget from '../../ProductPackages/components/SelectProductWidget';
import SelectProductPackageWidget from '../../ProductPackages/components/SelectProductPackageWidget';
import SelectPartnerWidget from '../../../common/SelectPartnerWidget';

export default function CreateServiceOrderDialog(props: $TSFixMe) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [serviceOrderData, setServiceOrderData] = useState({
    description: '',
  });
  const [addressId, setAddressId] = useState<number | null>(null);
  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [productPackage, setProductPackage] = useState();
  const [partner, setPartner] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setServiceOrderData((prevState) => {
      return {...prevState, addressId: addressId};
    });
  }, [addressId]);

  const handleChange = (e: $TSFixMe) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    serviceOrderData[name] = value;
    setServiceOrderData({...serviceOrderData});
  };

  const createServiceOrder = () => {
    const jwt = localStorage.getItem('Authorization');
    const body = JSON.stringify({
      description: (serviceOrderData as $TSFixMe).description,
      addressId: addressId,
      productPackageId: productPackage
        ? (productPackage as $TSFixMe)?.id
        : null,
      productIds: products.map((product: $TSFixMe) => product.id),
      partnerId: partner ? (partner as $TSFixMe)?.id : null,
      reference: (serviceOrderData as $TSFixMe).reference,
      state: 'PENDING',
      unitAccessNotes: (serviceOrderData as $TSFixMe).unit_access_notes,
      contactFirstName: (serviceOrderData as $TSFixMe).contact_first_name,
      contactLastName: (serviceOrderData as $TSFixMe).contact_last_name,
      contactEmail: (serviceOrderData as $TSFixMe).contact_email,
      contactPhone: (serviceOrderData as $TSFixMe).contact_phone,
    });
    return fetch(`${SERVER_URL}v1/service-orders`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
      body: body,
    })
      .then((res) => {
        if (res.status === 409) {
          return res;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        if (res && res.id) {
          console.log(res);
          history.push('/service-orders');
          handleClose();
        } else {
          setSnackBarVisible(true);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const hideSnackBar = (event: $TSFixMe) => {
    setSnackBarVisible(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} color={'secondary'} size={'small'}>
        <AddIcon fontSize="small" /> Create
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'md'}
        fullWidth
        disableEnforceFocus>
        <DialogTitle id="form-dialog-title">Create Service Order</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="contact_first_name"
            label="First Name"
            type="text"
            name="contact_first_name"
            color={'secondary'}
            fullWidth
            onChange={handleChange}
            value={(serviceOrderData as $TSFixMe).contact_first_name}
          />
          <TextField
            autoFocus
            margin="dense"
            id="contact_last_name"
            label="Last Name"
            type="text"
            name="contact_last_name"
            color={'secondary'}
            fullWidth
            onChange={handleChange}
            value={(serviceOrderData as $TSFixMe).contact_last_name}
          />
          <TextField
            autoFocus
            margin="dense"
            id="contact_email"
            label="Email"
            type="text"
            name="contact_email"
            color={'secondary'}
            fullWidth
            onChange={handleChange}
            value={(serviceOrderData as $TSFixMe).contact_email}
          />
          <TextField
            autoFocus
            margin="dense"
            id="contact_phone"
            label="Phone"
            type="text"
            name="contact_phone"
            color={'secondary'}
            fullWidth
            onChange={handleChange}
            value={(serviceOrderData as $TSFixMe).contact_phone}
          />
          <SelectPartnerWidget setPartner={setPartner} />
          <TextField
            autoFocus
            margin="dense"
            id="reference"
            label="Reference"
            type="text"
            name="reference"
            color={'secondary'}
            fullWidth
            onChange={handleChange}
            value={(serviceOrderData as $TSFixMe).reference}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="text"
            name="description"
            color={'secondary'}
            rows={4}
            fullWidth
            onChange={handleChange}
            multiline
            value={(serviceOrderData as $TSFixMe).description}
          />
          <TextField
            autoFocus
            margin="dense"
            id="unit_access_notes"
            label="Unit Access Notes"
            type="text"
            name="unit_access_notes"
            color={'secondary'}
            rows={4}
            fullWidth
            onChange={handleChange}
            multiline
            value={(serviceOrderData as $TSFixMe).unit_access_notes}
          />
          <SelectProductPackageWidget setProductPackage={setProductPackage} />
          <SelectProductWidget setProducts={setProducts} />
          <AddressLookupWidget setAddressId={setAddressId} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={createServiceOrder}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBarVisible}
        onClose={hideSnackBar}
        message="An error occurred"
      />
    </>
  );
}
