import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import {SERVER_URL} from '../../../constants';
import {sortByIdDesc} from '../../../util/SortingHelpers';
import Note from '../../RequestForProposal/components/Note';

const styles = (theme: $TSFixMe) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  root: {
    width: '100%',
    alignItems: 'center',
  },

  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  actionsContainer: {
    marginBottom: theme.spacing(2),
  },

  resetContainer: {
    padding: theme.spacing(3),
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
});

function ServiceOrderNotesPanel(props: $TSFixMe) {
  const [notes, setNotes] = useState<$TSFixMe>([]);
  const [page, setPage] = useState(0);

  const getData = () => {
    const url = `${SERVER_URL}v1/service-orders/${(props as $TSFixMe).match.params.id}/notes?page=${page}&pageSize=20`;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(url, {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ Authorization: string | null; 'Content-Typ... Remove this comment to see the full error message
      headers: {
        Authorization: fbjwt,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res?._embedded?.models) {
          setNotes((prevState: $TSFixMe) => {
            return page === 0
              ? res._embedded.models
              : [...prevState, ...res._embedded.models];
          });
        }

        if (res?.page && page < res.page.totalPages - 1) {
          setPage((currentPage) => currentPage + 1);
        }
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const submitNote = () => {
    const noteEl = document.getElementById('note_input');
    const value = (noteEl as $TSFixMe).value;
    (noteEl as $TSFixMe).value = null;
    const fbjwt = localStorage.getItem('Authorization');
    return fetch(`${SERVER_URL}v1/notes`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: fbjwt,
      },
      body: JSON.stringify({
        service_order_id: (props as $TSFixMe).match.params.id,
        note: value,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setNotes((prevState: $TSFixMe) => {
          return [...prevState, res];
        });
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const noteData =
    notes &&
    notes.sort(sortByIdDesc).map((note: $TSFixMe) => {
      return <Note key={note.id} note={note} />;
    });

  return (
    <Grid item xs={12} md={3}>
      <Card
        variant={'outlined'}
        style={{
          backgroundColor: '#f7f7f7',
          width: '100%',
          alignSelf: 'center',
          marginTop: '20px',
          maxHeight: 'calc(100% - 40px)',
          borderRadius: 10,
        }}>
        <div style={{padding: 10, textAlign: 'left'}}>
          <TextField
            id="note_input"
            multiline
            maxRows={6}
            placeholder="Record a note..."
            variant="outlined"
            style={{
              backgroundColor: '#fff',
              marginBottom: 10,
              borderRadius: 10,
              width: '100%',
            }}
          />
          <Button
            variant="contained"
            onClick={submitNote}
            color="secondary"
            style={{float: 'right'}}>
            Submit
          </Button>
          <br />
          <br />
          <br />
        </div>
        <div
          style={{
            padding: 10,
            textAlign: 'left',
            height: '100%',
            overflowY: 'scroll',
          }}>
          {noteData}
        </div>
      </Card>
    </Grid>
  );
}

export default withStyles(styles)(ServiceOrderNotesPanel);
