import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const styles = (theme: $TSFixMe) => ({
  root: {
    marginLeft: theme.spacing(2),
    flex: 1,
    backgroundColor: 'none',
    border: 'none',
    maxWidth: 600,
  },
  heading: {
    fontWeight: 800,
  },
  body1: {
    fontSize: '14px',
  },
});

function ServiceOrderDetails(props: $TSFixMe) {
  const {classes} = props;

  /*
  attachmentReferences: []
  completionDate: null
  contactEmail: "phillipstack@gmail.com"
  contactFirstName: "Jim"
  contactLastName: "Renter"
  contactPhone: "5195469170"
  createdDate: "2024-12-20T19:27:00.929+00:00"
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
  id: 1
  name: "SO-000001"
  productPackage: null
  products: []
  reference: "ThisIsAReference"
  requestForProposal: null
  scheduledDate: null
  state: "PENDING"
  technicians: []
  uid: "40f649fb-7166-4f1a-bf41-b20ba32dc809"
  unitAccessNotes: "These are some unit access notes."
   */

  return (
    <Box className={classes.root}>
      <Typography
        variant={'subtitle1'}
        component={'p'}
        className={classes.heading}>
        {`Contact: ${props.serviceOrder.contactFirstName} ${props.serviceOrder.contactLastName}`}
      </Typography>
      <Typography
        variant={'subtitle1'}
        component={'p'}
        className={classes.heading}>
        {`Phone: ${props.serviceOrder.contactPhone}`}
      </Typography>
      <Typography
        variant={'subtitle1'}
        component={'p'}
        className={classes.heading}>
        {`Email: ${props.serviceOrder.contactEmail}`}
      </Typography>
      <Typography
        variant={'subtitle1'}
        component={'p'}
        className={classes.heading}>
        Description:
      </Typography>
      <Typography variant="body1" component="p" className={classes.body1}>
        {props.serviceOrder.description}
      </Typography>
      <br />
      <Typography
        variant={'subtitle1'}
        component={'p'}
        className={classes.heading}>
        Unit Access Notes:
      </Typography>
      <Typography variant="body1" component="p" className={classes.body1}>
        {props.serviceOrder.unitAccessNotes}
      </Typography>
    </Box>
  );
}

export default withStyles(styles)(ServiceOrderDetails);
