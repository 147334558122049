import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {SERVER_URL} from '../../../constants';
import {useHistory} from 'react-router-dom';
import {Snackbar} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import ProductList from './ProductList';

export default function CreateProductPackageDialog(props: $TSFixMe) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [packageData, setPackageData] = useState({
    name: '',
    description: '',
  });
  const [snackBarVisible, setSnackBarVisible] = useState(false);
  const [products, setProducts] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: $TSFixMe) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    packageData[name] = value;
    setPackageData({...packageData});
  };

  const createPackage = () => {
    const jwt = localStorage.getItem('Authorization');
    const body = JSON.stringify({
      name: (packageData as $TSFixMe).name,
      description: (packageData as $TSFixMe).description,
      productIds: products.map((product: $TSFixMe) => product.id),
    });
    return fetch(`${SERVER_URL}v1/product-packages`, {
      method: 'POST',
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ 'Content-Type': string; Authorization: str... Remove this comment to see the full error message
      headers: {
        'Content-Type': 'application/json',
        Authorization: jwt,
      },
      body: body,
    })
      .then((res) => {
        if (res.status === 409) {
          return res;
        } else {
          return res.json();
        }
      })
      .then((res) => {
        if (res && res.id) {
          console.log(res);
          history.push('/product-packages');
          handleClose();
        } else {
          setSnackBarVisible(true);
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.status === 403) {
          window.location.href = '/login';
        }
      });
  };

  const hideSnackBar = (event: $TSFixMe) => {
    setSnackBarVisible(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} color={'secondary'} size={'small'}>
        <AddIcon fontSize="small" /> Create
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'md'}
        fullWidth
        disableEnforceFocus>
        <DialogTitle id="form-dialog-title">Create Package</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Package Name"
            type="text"
            name="name"
            color={'secondary'}
            fullWidth
            onChange={handleChange}
            value={(packageData as $TSFixMe).name}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="text"
            name="description"
            color={'secondary'}
            rows={4}
            fullWidth
            onChange={handleChange}
            multiline
            value={(packageData as $TSFixMe).description}
          />
        </DialogContent>
        <ProductList products={products} setProducts={setProducts} />
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" color="secondary" onClick={createPackage}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackBarVisible}
        onClose={hideSnackBar}
        message="An error occurred"
      />
    </>
  );
}
